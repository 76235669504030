#Contest {
  padding-bottom: 20px;

  .container {
    margin: 7.5px;
    border-width: 0.8px;
    border-radius: 10px;
    background-color: #fff;
    width: calc(var(--WIDTH) - 15px);
    box-shadow: var(--SHADOW);
  }
  .dimg {
    padding: 5px;
    border-radius: 10px;
    object-fit: contain;
    width: calc(var(--WIDTH) - 25px) !important;
    height: 150px !important;
  }

  .img {
    padding: 5px;
    border-radius: 10px;
    object-fit: contain;
    width: calc(var(--WIDTH) - 25px) !important;
    height: calc(var(--WIDTH) - 25px) !important;
  }

  .imgA {
    padding: 5px;
    border-radius: 10px;
    object-fit: contain;
    width: calc(var(--WIDTH) - 25px) !important;
    height: 150px !important;
  }

  .imgW {
    padding: 5px;
    border-radius: 10px;
    object-fit: contain;
    width: 100% !important;
    height: 120px !important;
  }

  .footer {
    padding: 10px;
    margin-top: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: var(--ICE_BLUE);
  }
  .brandInfo {
    padding: 0px 10px 10px 10px;
  }
  .logo {
    width: 35px;
    height: 35px;
    border-radius: 1000px;
  }
  .brandBtn {
    font-size: 9px;
    padding: 3px 7px;
    border-radius: 100px;
    border: 1px solid var(--PRIMARY_BLUE);
  }
  .joinContestBtn {
    font-size: 11px;
    padding: 2px 6px;
    border-radius: 10px;
    border: 1px solid var(--PRIMARY_BLUE);
    user-select: none;
    color: var(--PRIMARY_BLUE) !important;
    cursor: pointer;
  }

  .joinContestBtn[contested='true'] {
    cursor: not-allowed;
  }

  .groupIcon {
    font-size: 17px;
    margin: 0px 7px;
    color: var(--ERROR);
  }
  .drawImg {
    border-radius: 8px;
    object-fit: contain;
    width: calc(100% - 16px);
    height: calc(var(--WIDTH) - 16px);
  }
  .subHeading {
    color: var(--MUTED_BLUE) !important;
  }
  .content {
    padding: 3px 10px;
    font-size: 15px !important;
  }
  .contentSmall {
    padding: 3px 10px;
    font-size: 13px !important;
  }
  .card {
    padding: 10px;
    margin: 7.5px;
    margin-bottom: 0;
    border-radius: 10px;
    box-shadow: var(--SHADOW);
  }
  .tourchContainer {
    text-align: center;
    border-radius: 50px;
    padding: 12px 12px 10px;
    position: absolute;
    width: 20px;
    right: 40px;
    top: 40px;
  }
  .uploadQrBtn {
    margin: 10px 0px 15px;
    white-space: nowrap;
    width: 180px;
  }
  .video {
    padding: 20px;
    width: calc(100% - 40px);
  }
}
