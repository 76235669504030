#AdFeed {
  // Feed
  .container {
    background-color: var(--LIGHT_COLOR);
    height: -webkit-fill-available;
  }
  .pagerView {
    height: calc(100vh - 64px);
  }
  .page {
    display: flex;
    height: inherit;
    flex-direction: column;
  }
  .post {
    flex: 1;
    overflow: scroll;
  }
  .claimedContainer {
    height: calc(var(--MIN_HEIGHT) - var(--WIDTH) - 95px);
    background-color: rgba(52, 52, 52, 0.6);
    justify-content: center;
    align-items: center;
    margin-top: auto;
  }
  .claimedMsg {
    padding: 0px 30px;
    color: #fff !important;
    font-size: 18px !important;
    text-align: center !important;
  }

  // Feed Item
  .drawImg {
    width: var(--WIDTH);
    height: var(--WIDTH);
    align-self: center;
    margin-bottom: 6px;
    border-radius: 12px;
    object-fit: contain;
  }
  .text {
    margin: 0px 20px;
  }
  .descriptionHolder {
    margin: 6px 0px 10px 0px;
    background-color: var(--LIGHT_COLOR);
    max-height: calc(var(--MIN_HEIGHT) * 0.3);
  }
  .descriptionHolder[os='mobile'] {
    max-height: calc(var(--MIN_HEIGHT) - 230px - var(--WIDTH));
    overflow: scroll;
  }

  // Feed Footer
  .btnHolder {
    // flex: 0.25;
    text-align: center;
  }
  .brandLogo {
    width: 18px;
    height: 18px;
    border-radius: 25px;
  }
  .feedFooterIcon {
    font-size: 18px;
    color: var(--DARK_BLUE);
  }
  .feedFooterStarIcon {
    font-size: 25px !important;
    margin-bottom: -5px;
  }

  // Feed Header
  .headerContainer {
    padding: 10px 15px;
    background-color: #fff;
  }
  .brand {
    display: flex;
    align-items: center;
    margin-left: 6px;
    width: 55%;
    height: 30px;
  }
  .headerBrandLogo {
    width: 36px;
    height: 36px;
    margin-right: 10px;
    border-radius: 18px;
  }
  .myntsHolder {
    background-color: #ffe28a;
    padding: 5px 8px;
    border-radius: 16px;
    margin: 0px 8px;
  }
  .myntsHolder[iscontested='true'] {
    background-color: #ffe28a;
    animation: myntsBlinkAnimation 2.5s linear infinite;
  }
  .myntsTxt {
    margin-left: 5px;
  }
  .myntsIcon {
    height: 20px;
    width: 20px;
  }

  // For Swipe
  .swipeContainer {
    position: absolute;
    width: max-content;
    text-align: center;
    margin: auto;
    padding: 8px 15px;
    border-radius: 20px;
    background-color: #dbdfe4;
  }
  .swipeContainer[moment='horizontal'] {
    position: relative;
    animation: swipeHorizontalAnimation 1s infinite alternate;
  }
  .swipeContainer[moment='vertical'] {
    bottom: 60px;
    right: calc((var(--WIDTH) / 2) - 45px);
    left: calc((var(--WIDTH) / 2) - 45px);
    animation: swipeVerticalAnimation 1s infinite alternate;
  }
  @keyframes swipeVerticalAnimation {
    from {
      bottom: 75px;
    }
    to {
      bottom: 60px;
    }
  }
  @keyframes swipeHorizontalAnimation {
    from {
      right: 10px;
    }
    to {
      right: 0px;
    }
  }

  @keyframes myntsBlinkAnimation {
    0% {
      background-color: #fff;
    }
    25% {
      background-color: #ffe28a;
    }
    50% {
      background-color: #fff;
    }
    75% {
      background-color: #ffe28a;
    }
    100% {
      background-color: #fff;
    }
  }
}
