#Promos {
  .listStatusContainer {
    right: 0px;
    top: 0px;
    padding: 3px;
    position: absolute;
    border-top-left-radius: 6.5px;
    border-bottom-left-radius: 6.5px;
    background-color: rgba(255, 255, 255, 0.75);
  }
  .listStatus {
    margin: 0px 3px;
    font-size: 10px !important;
  }
  .statusIcon {
    font-size: 12px;
  }
  .expiredContainer {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0.75);
  }
  .redeemImg {
    width: 100px;
    height: 100px;
    opacity: 0.8;
  }

  .detailsImg {
    border-radius: 10px;
    object-fit: contain;
    width: calc(var(--WIDTH) - 40px);
    height: calc(var(--WIDTH) - 40px);
  }
  .claimIcon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
  }
  .container {
    padding: 20px;
    margin-bottom: 6.5px;
    background-color: #fff;
  }
  .name {
    flex-shrink: 1;
    flex-wrap: wrap;
    text-transform: capitalize;
  }
  .dashedLine {
    height: 1px;
    margin: 15px 0px;
    border-radius: 1px;
    border-bottom: 1px dashed #e6e6e6;
  }
  .statusBtn {
    font-size: 14px;
    padding: 12px 16px;
    border-radius: 10px;
    margin-bottom: 10px;
    color: #fff !important;
  }
  .couponContainer {
    padding: 10px;
    border-radius: 7px;
    background-color: #ececec;
  }
  .qty {
    width: 100px;
    flex-wrap: wrap;
    text-align: right;
  }
  .logo {
    width: 35px;
    height: 35px;
    border-radius: 100px;
  }
  .brandBtn {
    font-size: 9px;
    padding: 2px 7px;
    border-radius: 50px;
    border: 0.7px solid var(--PRIMARY_BLUE);
  }
  .copyContainer {
    padding: 2px 8px;
    border-radius: 10px;
    border: 0.5px solid var(--PRIMARY_BLUE);
  }
  .promoNoImgBody {
    display: flex;
    height: inherit;
    flex-direction: column;
    justify-content: center;
  }
}
