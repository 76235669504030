#WebBrands {
  .container {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 70px;
  }
  .brandtabs {
    margin-left: calc(10% + 36px);
    margin-right: calc(10% + 36px);
  }
  .drawerOption {
    padding-left: 12px;
    padding-block: 10px;
    border-bottom: 1px solid rgb(222 215 215);
    gap: 10px;
  }
  .burger-menu {
    display: none;
  }
  .right-side {
    display: none;
  }
  .searchInput {
    width: -webkit-fill-available;
    margin-left: 7px;
  }
  .searchInput input::placeholder {
    font-size: 13px;
  }
  .searchIcon[isfocus='true'] {
    border-bottom: 2px solid var(--PRIMARY_BLUE);
  }
  .row {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .rowSB {
    display: flex;
    align-items: center;
    padding: 15px;
    box-sizing: border-box;
    justify-content: space-between;
  }

  .MenuItems {
    display: flex; /* Shown on larger screens */
    justify-content: space-between;
    padding-right: 75px;
    gap: 15px;
  }
  .MenuItems.menu-item {
    text-decoration: none;
    transition: color 0.3s ease;
  }
  .footer {
    padding: 8px 10px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: var(--ICE_BLUE);
  }
  .banner {
    object-fit: cover;
    width: 100%;
    height: calc(var(--WIDTH) / 2);
    height: 420px;
  }
  .noBanner {
    background-image: linear-gradient(160deg, #2f80ed, #56ccf2);
  }
  .noBannerText {
    color: #fff !important;
    text-align: center;
    padding-top: calc(var(--WIDTH) / 4 - 40px);
  }
  .logoContainer {
    width: 132.5px;
    height: 132.5px;
    border-radius: 135px;
    background-color: #fff;
    position: absolute;
    bottom: -40px;
    left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo {
    width: 125px;
    height: 125px;
    border-radius: 10000px;
    object-fit: contain;
  }
  .brandName {
    margin-top: 55px;
  }
  .followBtn {
    color: red;
    cursor: pointer;
    margin-right: 5px;
  }
  .icon {
    margin: 0px 5px;
    font-size: 17px;
  }
  .subHeading {
    padding: 0px 4px 3px 0px;
    font-family: var(--BOLD_FONT) !important;
  }
  .rowContainer {
    padding-bottom: 7px;
    align-items: flex-start;
  }
  .plusIcon {
    width: 35px;
    padding: 5px;
    border-radius: 50;
    margin: 10px 0px;
    margin: 0px 20px;
  }
  .aboutSections {
    margin-left: 1px;
    border-radius: 10px;
    padding: 15px;
  }
  .userPolicyOptionCntainer {
    padding: 5px 0px;
  }
  .userPolicyOption {
    padding: 10px;
    cursor: pointer;
  }
  .Brandfooter {
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 120px;
    justify-content: center;
  }
  .footer-menu {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 0;
    list-style: none;
    margin: 0;
  }
  .menu-item {
    padding-top: 10px;
  }

  //Aboutus page styles
  .Heading {
    height: 120px;
    background: #f8f8f8;
    margin-top: 20px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 150px;
  }
  .aboutus-container {
    padding-top: 50px;
    margin-bottom: 120px;
    padding-left: 80px;
    padding-right: 80px;
  }
  .policy-container {
    padding-top: 50px;
    margin-bottom: 180px;
    width: 100%;
    display: flex;
  }
  .sidebar-menu {
    flex: 1;
    max-width: 25%;
    padding: 20px;
  }
  .content-area {
    flex: 3;
    padding: 20px;
  }

  //contactus page styles
  .contact-content {
    padding-left: 150px;
  }
  /* Menu buttons styling */
  .menu-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    text-align: left;
  }
  .menu-button:hover {
    transform: scale(0.95);
  }
  // .content {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   padding: 0 10px;
  // }

  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .icon-image {
    width: 600px;
    height: 400px;
    object-fit: cover;
  }
  .nopolicy {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
  }

  @media (max-width: 360px) {
    .brandtabs {
      padding: 5px;
    }
    .burger-menu {
      display: block;
    }
    .Heading {
      height: 120px;
      background: #f8f8f8;
      margin-top: 20px;
      display: flex;
      justify-content: left;
      align-items: center;
      padding-left: 150px;
    }
    .right-side {
      display: block;
    }
    .content {
      padding-left: 20px;
      padding-right: 20px;
    }
    .contact-content {
      padding-left: 20px;
    }
    .MenuItems {
      display: none;
    }
    .banner {
      width: 100%;
      height: 200px;
    }
    .policy-container {
      display: flex;
      flex-direction: column;
    }
    .sidebar-menu {
      max-width: 100%;
      padding: 10px;
    }
    .content-area {
      max-width: 100%;
      padding: 10px;
    }
  }
  @media (max-width: 480px) {
    .brandtabs {
      padding: 5px;
      margin-left: 0px;
      margin-right: 0px;
    }
    .Heading {
      height: 120px;
      padding-left: 30px;
      margin-top: 0px;
    }
    .right-side {
      display: block;
    }
    .content {
      padding-left: 20px;
      padding-right: 20px;
    }
    .contact-content {
      padding-left: 20px;
    }
    .burger-menu {
      display: block;
    }
    .MenuItems {
      display: none;
    }
    .banner {
      width: 100%;
      height: 200px;
    }
    .policy-container {
      display: flex;
      flex-direction: column;
    }
    .sidebar-menu {
      max-width: 100%;
      padding: 10px;
    }
    .content-area {
      max-width: 100%;
      padding: 10px;
    }
  }
  @media (max-width: 600px) {
    .burger-menu {
      display: block;
    }
    .right-side {
      display: block;
    }
    .Heading {
      height: 120px;
      padding-left: 30px;
      margin-top: 0px;
    }
    .content {
      padding-left: 20px;
      padding-right: 20px;
    }
    .contact-content {
      padding-left: 20px;
    }
    .MenuItems {
      display: none;
    }
    .banner {
      width: 100%;
      height: 200px;
    }
    .policy-container {
      display: flex;
      flex-direction: column;
    }
    .sidebar-menu {
      max-width: 100%;
      padding: 10px;
    }
    .content-area {
      max-width: 100%;
      padding: 10px;
    }
  }
}
