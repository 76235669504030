* {
  padding: 0px;
  margin: 0px;
}

:root {
  --PRIMARY_BLUE: #1d89cc;
  --ICE_BLUE: #f0f8ff;
  --BRIGHT_BLUE: rgba(15, 161, 255, 1);
  --LIGHT_BLUE: rgba(121, 202, 255, 1);
  --DARK_BLUE: rgba(0, 65, 107, 1);
  --NAVY_BLUE: rgba(0, 84, 139, 1);
  --DULL_BLUE: rgba(169, 221, 255, 1);
  --MUTED_BLUE: rgba(13, 42, 77, 0.48);
  --MUTED_BORDER: rgba(13, 42, 77, 0.15);
  --DARK_COLOR: #0d2a4d;
  --LIGHT_COLOR: #fffafa;
  --MUTED_COLOR: #dcdcdc;
  --GREEN_COLOR: green;
  --DEFAULT_FONT: MynyfyRegular;
  --BOLD_FONT: MynyfyBold;
  --SEMI_BOLD_FONT: MynyfySemiBold;
  --ITALIC_FONT: MynyfyItalic;
  --SEMI_BOLD_ITALIC_FONT: MynyfySemiBoldItalic;
  --BOLD_ITALIC_FONT: MynyfyBoldItalic;
  --GYAHEGI: MynyfyGyahegi;
  --WARNING: #ffc400;
  --ERROR: #f44336;
  --MIN_HEIGHT: 100vh;
  --WIDTH: min(100vw, 425px);
  --SHADOW: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

@font-face {
  font-family: MynyfyLight;
  src: url(./assets/fonts/Karla-Light.ttf);
}

@font-face {
  font-family: MynyfyRegular;
  src: url(./assets/fonts/Karla-Regular.ttf);
}

@font-face {
  font-family: MynyfyMedium;
  src: url(./assets/fonts/Karla-Medium.ttf);
}

@font-face {
  font-family: MynyfySemiBold;
  src: url(./assets/fonts/Karla-SemiBold.ttf);
}

@font-face {
  font-family: MynyfyBold;
  src: url(./assets/fonts/Karla-Bold.ttf);
}

@font-face {
  font-family: MynyfyItalic;
  src: url(./assets/fonts/Karla-Italic.ttf);
}

@font-face {
  font-family: MynyfySemiBoldItalic;
  src: url(./assets/fonts/Karla-SemiBoldItalic.ttf);
}

@font-face {
  font-family: MynyfyBoldItalic;
  src: url(./assets/fonts/Karla-BoldItalic.ttf);
}

@font-face {
  font-family: MynyfyGyahegi;
  src: url(./assets/fonts/Gyahegi.otf);
}


/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
html {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

body {
  background-color: #e8e8e8;
  font-family: MynyfyRegular;
}

.itemCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  display: flex;
  align-items: center;
}

.rowSB {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rowSA {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.rowSE {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.rowC {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rowWrap {
  flex-wrap: wrap;
}

.link {
  color: var(--PRIMARY_BLUE);
  cursor: pointer;
  user-select: none;
}
.cursor {
  cursor: pointer;
  user-select: none;
}

.backBtn {
  top: 15px;
  left: 15px;
  fill: #fff !important;
  width: 30px !important;
  height: 30px !important;
  padding: 5px;
  position: absolute;
  cursor: pointer;
}
.dotIcon {
  width: 8px !important;
  height: 8px !important;
  fill: grey !important;
  margin: 4.5px 5px 0px;
}
.filterOption {
  flex: 1;
  text-align: center;
  border-right: 1px solid rgb(228, 224, 224);
}
.filterContainer {
  flex: 1;
  padding: 10px;
  background-color: #fff;
  box-shadow: var(--SHADOW);
}
.divider {
  border-bottom: 1px solid #e3e3e3;
  margin: 15px 0px;
}
.singleLine {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// loader styles
.loader {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  color: var(--PRIMARY_BLUE);
  box-sizing: border-box;
  animation: animloader 1s linear infinite alternate;
}

@keyframes animloader {
  0% {
    box-shadow: -38px -6px, -14px 6px, 14px -6px;
  }

  33% {
    box-shadow: -38px 6px, -14px -6px, 14px 6px;
  }

  66% {
    box-shadow: -38px -6px, -14px 6px, 14px -6px;
  }

  100% {
    box-shadow: -38px 6px, -14px -6px, 14px 6px;
  }
}

.MuiInputLabel-sizeSmall[data-shrink='false'] {
  font-size: 13px;
  margin-top: 3px;
}
