#webHome {
  user-select: none;
  padding-left: 30px;
  padding-right: 30px;

  .topBar {
    padding: 13px 20px;
    border-radius: 0px;
    position: fixed;
    width: var(--WIDTH);
    box-sizing: border-box;
    box-shadow: none;
  }

  .logo {
    width: 90px;
    object-fit: contain;
    cursor: pointer;
  }

  .bannerContainer {
    text-align: center;
    margin: 20px 0px;
  }

  .banner {
    width: calc(100% - 40px);
    border-radius: 15px;
  }

  .featureContainer {
    margin: 20px 20px 0;
  }

  .feature {
    margin: 0px 5px;
    padding: 15px;
    border-radius: 12px;
  }

  .featureIcon {
    width: fit-content;
    margin: auto auto 10px;
    padding: 10px 10px 6px;
    border-radius: 100px;
    background-color: #e3f2fd;
  }

  .itemContainer {
    margin: 0px 5px;
    text-align: center;
    width: calc((100% - 40px) / 4);
  }

  .policyContainer {
    cursor: pointer;
    padding: 20px 20px 30px;
  }

  .infoIcon {
    font-size: 11px;
    color: var(--PRIMARY_BLUE);
    margin: 0px 3px;
  }

  .headerSection {
    padding: 30px 20px 0px;
  }

  .myntVal {
    font-size: 24px !important;
    color: var(--NAVY_BLUE) !important;
    font-family: var(--BOLD_FONT) !important;
  }

  .offer {
    padding: 20px 3px 5px;
    display: flex;
    overflow-x: scroll;
  }

  .homeContestCard {
    width: calc(var(--WIDTH) - 86px);
    margin-right: 10px;
  }

  .contestImage {
    object-fit: cover;
    height: calc((var(--WIDTH) - 86px) / 2);
    width: calc(var(--WIDTH) - 86px);
  }

  .all {
    margin: auto -20px auto 0px;
    background-color: rgb(245, 240, 240);
    border-radius: 50px;
    padding: 8px;
  }
  .storeTypeBanner {
    height: 150px;
    margin-right: -20px;
  }
  .dealBannerContainer {
    width: calc(var(--WIDTH) - 50px);
    border-radius: 12px;
    overflow: hidden;
    margin: auto;
  }
  .dealBannerImg {
    width: 100%;
    height: auto;
    display: block;
    object-fit: contain;
  }
  @media screen and (max-width: 350px) {
    .storeTypeBanner {
      height: 130px;
    }
  }
}

#WebDeals {
  user-select: none;
  padding-left: 30px;
  padding-right: 30px;

  .topBar {
    padding: 13px 20px;
    border-radius: 0px;
    position: fixed;
    width: var(--WIDTH);
    box-sizing: border-box;
    box-shadow: none;
  }

  .logo {
    width: 90px;
    object-fit: contain;
    cursor: pointer;
  }

  .bannerContainer {
    text-align: center;
    margin: 20px 0px;
  }

  .banner {
    width: calc(100% - 40px);
    border-radius: 15px;
  }

  .featureContainer {
    margin: 20px 20px 0;
  }

  .feature {
    margin: 0px 5px;
    padding: 15px;
    border-radius: 12px;
  }

  .featureIcon {
    width: fit-content;
    margin: auto auto 10px;
    padding: 10px 10px 6px;
    border-radius: 100px;
    background-color: #e3f2fd;
  }

  .itemContainer {
    margin: 0px 5px;
    text-align: center;
    width: calc((100% - 40px) / 4);
  }

  .policyContainer {
    cursor: pointer;
    padding: 20px 20px 30px;
  }

  .infoIcon {
    font-size: 11px;
    color: var(--PRIMARY_BLUE);
    margin: 0px 3px;
  }

  .headerSection {
    padding: 30px 20px 0px;
  }

  .myntVal {
    font-size: 24px !important;
    color: var(--NAVY_BLUE) !important;
    font-family: var(--BOLD_FONT) !important;
  }

  .offer {
    padding: 20px 3px 5px;
    display: flex;
    overflow-x: scroll;
  }

  .homeContestCard {
    width: calc(var(--WIDTH) - 86px);
    margin-right: 10px;
  }

  .contestImage {
    object-fit: cover;
    height: calc((var(--WIDTH) - 86px) / 2);
    width: calc(var(--WIDTH) - 86px);
  }

  .all {
    margin: auto -20px auto 0px;
    background-color: rgb(245, 240, 240);
    border-radius: 50px;
    padding: 8px;
  }
  .storeTypeBanner {
    height: 150px;
    margin-right: -20px;
  }
  .dealBannerContainer {
    width: calc(var(--WIDTH) - 50px);
    border-radius: 12px;
    overflow: hidden;
    margin: auto;
  }
  .dealBannerImg {
    width: 100%;
    height: auto;
    display: block;
    object-fit: contain;
  }
  @media screen and (max-width: 350px) {
    .storeTypeBanner {
      height: 130px;
    }
  }
}
