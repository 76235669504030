#Stores {
  .noStore {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: calc(var(--WIDTH) - 50px);
  }
  .searchInput {
    width: -webkit-fill-available;
    margin-left: 7px;
  }
  .searchInput input::placeholder {
    font-size: 13px;
  }
  .searchIcon[isfocus='true'] {
    border-bottom: 2px solid var(--PRIMARY_BLUE);
  }
  .footer {
    margin-top: auto;
    padding: 8px 10px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: var(--ICE_BLUE);
  }
  .storeName {
    padding: 7px 10px;
    text-transform: capitalize;
  }
  .followBtn {
    color: red;
    cursor: pointer;
    margin: 0px 5px;
  }
  .noStoreImg {
    width: 200px;
    height: 200px;
    margin-right: 10px;
    border-radius: 6px;
    object-fit: contain;
  }
  .storeImgContainer {
    padding: 10px 0px;
    max-height: 220px;
    display: flex;
    overflow-x: scroll;
  }
  .storeImg {
    width: var(--WIDTH);
    height: calc(var(--WIDTH) / 2);
    border-radius: 6px;
    object-fit: contain;
    background-color: white;
  }
  .normalStoreImg {
    width: var(--WIDTH);
    height: var(--WIDTH);
    object-fit: fill;
    background-color: white;
  }
  .subHeading {
    white-space: nowrap;
    font-size: 13px !important;
    color: var(--MUTED_BLUE) !important;
    font-family: var(--BOLD_FONT) !important;
  }
  .contentSec {
    padding: 5px 0px 10px;
    overflow-wrap: anywhere;
  }
  .aboutSections {
    margin-left: 1px;
    border-radius: 10px;
    padding: 15px;
  }
  .userPolicyOption {
    padding: 10px;
    cursor: pointer;
  }
  .userPolicyOptionCntainer {
    padding: 5px 0px;
  }
  .rowContainer {
    padding-bottom: 7px;
    align-items: flex-start;
  }
  .drawerOption {
    padding-left: 12px;
    padding-block: 10px;
    border-bottom: 1px solid rgb(222 215 215);
    gap: 10px;
  }
  .offersDisplayCard {
    margin-block: 10px;
    margin-inline: 15px;
    padding-inline: 20px;
    padding-block: 10px;
    border-radius: 15px;
  }
  .InnerlogoName{
   display: flex;
   flex-direction: column;
   align-items: center;
   padding-bottom: 5px;
  //  justify-content: center;
  //  height: 40px;
  }
  .MenuItems-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;
  }

  /* Footer Styles */
  .Storefooter {
    background-color: #f8f8f8;
    padding: 20px 0;
    text-align: center;
    width: 100%;
    margin-bottom: -74px;
    padding-bottom: 30px;
  }

  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    list-style: none;
    margin: 0;
  }

  .menu-item {
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;
  }

  .menu-item:hover {
    color: #007bff; /* Example hover effect */
  }

  .whatsAppIcon {
    position: sticky;
    bottom: 0px;
    text-align: right;
    width: fit-content;
    margin-left: auto;
    z-index: 1;
    padding: 10px 10px;
  }

  /* Ultra-small devices (below 360px) */
  @media (max-width: 360px) {
    .storelayout {
      padding-left: 5px;
      padding-right: 5px;
    }
    .normalStoreImg {
      width: 100%;
      height: 200px; /* Further reduce height for very small screens */
    }
    .StoreName {
      width: 150px;
      font-size: 14px; /* Adjust text size */
    }
    .row.cursor {
      gap: 0px;
    }
    .menu-item {
      font-size: 12px; /* Smaller font for footer links */
      padding: 5px 10px;
    }
  }

  /* Mobile screens (up to 480px) */
  @media (max-width: 480px) {
    .storelayout {
      padding-left: 10px;
      padding-right: 10px;
    }
    .burger-menu {
      display: block; /* Show burger menu on small screens */
    }
    .MenuItems {
      display: none; /* Hide on mobile */
    }
    .normalStoreImg {
      width: 100%;
      height: 250px; /* Smaller height for mobile */
    }
    .StoreName {
      width: 160px;
    }
    .row.cursor {
      gap: 0px; /* No gap on small screens */
    }
    .footer-menu {
      display: flex;
      flex-wrap: wrap;
    }
    .footer-content {
      flex-direction: column;
    }

    .menu-item {
      padding: 5px 10px;
    }
  }
}
