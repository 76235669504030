#MynyfyHeader {
  position: relative;
  height: 62px;
  z-index: 10;

  .mynyfyHeader {
    padding: 10px 15px;
    border-radius: 0;
    position: fixed;
    width: var(--WIDTH);
    box-sizing: border-box;
  }
}

#MynyfyAlert {
  .alertBtn {
    font-size: 16px;
    padding: 0px 10px;
    text-transform: capitalize;
    font-family: var(--SEMI_BOLD_FONT);
  }
}

#MynyfyModal {
  .mainContainer {
    height: 100%;
    outline: none;
    display: flex;
  }
  .subContainer {
    outline: none;
    margin: auto auto 0;
    width: var(--WIDTH);
  }
  .childContainer {
    padding: 20px;
    background-color: #fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

#MynyfyTabs {
  .tab {
    height: calc(var(--MIN_HEIGHT) - 48px);
  }
  .tabsBackBtn {
    left: 15px;
    top: 11.5px;
    height: 25px;
    cursor: pointer;
    position: absolute;
  }
}

#SnackBar {
  width: var(--WIDTH);
  align-items: center;
  .snackBar {
    width: var(--WIDTH);
    align-items: center;
  }
}

#Refer {
  .referContainer {
    margin: 16px;
    padding: 15px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  .referIconConatiner {
    padding: 10px;
    border-radius: 100px;
    background-color: #e3f2fd;
  }
  .referIcon {
    width: 27px;
    height: 27px;
    object-fit: contain;
  }
  .referContent {
    padding-left: 20px;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: row;
  }
}

#MyUploadImg {
  .MyUploadImgLabel {
    margin: auto;
    max-width: 18ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .MyUploadImgBtn {
    margin: auto;
    color: white;
    max-width: 24ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }
}

#MynyfyDoubleCard {
  padding-bottom: 30px;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  .dCard {
    width: calc((var(--WIDTH) - 30px) / 2);
    box-shadow: var(--SHADOW);
    background-color: #fff;
    border-radius: 6px;
    margin: 5px;
    display: flex;
    flex-direction: column;
  }
  .dImg {
    width: calc(((var(--WIDTH) - 30px) / 2) - 4px) !important;
    height: calc(((var(--WIDTH) - 30px) / 2) - 4px) !important;
    object-fit: contain;
    border-radius: 6px;
    display: block;
    margin: 2px;
  }
  .noImgContainer {
    border-radius: 200px;
    border: 2px solid #e2e2e2;
    width: calc(((var(--WIDTH) - 30px) / 2) - 20px);
    height: calc(((var(--WIDTH) - 30px) / 2) - 20px);
    justify-content: center;
    align-items: center;
    display: flex;
    margin: auto;
  }
}

#OrderModals {
  background-color: #fff;

  .orderModalContainer {
    border-radius: 10px;
    margin: 0px 3px 15px;
    padding: 12px 15px;
    background-color: #fff;
    box-shadow: var(--SHADOW);
  }
  .addressEditIcon {
    background-color: var(--PRIMARY_BLUE);
    border-radius: 50px;
    margin-left: 3px;
    padding: 1px 5px;
  }
  .noAddressMsg {
    padding: 10px 10px 40px;
  }
  .paymodeImg {
    width: 90px;
    height: 30px;
    object-fit: contain;
  }
  .trackingContainer {
    padding: 10px;
    border-radius: 7px;
    margin: 10px 0px;
    background-color: #ececec;
  }
}

#RadioBtn {
  .radioBtnCard {
    padding: 15px 0px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: var(--SHADOW);
  }
  .radioBtn {
    width: 16px;
    height: 16px;
    margin: 0px 15px;
    border-radius: 10px;
    border: 1px solid #707070;
  }
  .selectedRadioBtn {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--PRIMARY_BLUE);
  }
}

#ShowImages {
  position: relative;

  .carouselImg {
    width: calc(var(--WIDTH) - 40px);
    height: calc(var(--MIN_HEIGHT) - 40px);
    object-fit: contain;
    margin: 0px 3px;
  }
  .closeBtnContainer {
    top: -10px;
    right: 0px;
    z-index: 10;
    position: absolute;
    padding: 10px 0px 10px 20px;
    cursor: pointer;
  }
  .imgIndicationContainer {
    left: 0;
    right: 0;
    bottom: 20px;
    margin: auto;
    position: absolute;
    width: fit-content;
  }
  .indicator {
    width: 10px;
    height: 10px;
    margin: 0px 15px;
    border-radius: 20px;
    border: 2px solid #fff;
    cursor: pointer;
  }
  .activeIndicator {
    border: 2px solid var(--PRIMARY_BLUE);
    background-color: var(--PRIMARY_BLUE);
  }
}

#ShowSellerQR {
  .upiIdContainer {
    padding: 10px;
    border-radius: 7px;
    background-color: #ececec;
  }
}

#BottomTabs {
  .bottomNav {
    bottom: 0px;
    position: fixed;
    width: var(--WIDTH);
    background-color: #fff;
    overflow: scroll;
    box-sizing: border-box;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 1px 0px 10px -3px;
  }
  .bottomTabOption {
    padding: 3px 0px 5px;
    text-align: center;
    cursor: pointer;
  }
  .bottomTabIcon {
    width: 26px;
    height: 26px;
    fill: #000;
    padding-top: 5px;
    object-fit: contain;
  }
  .bottomTabLabel {
    white-space: nowrap;
    font-family: var(--BOLD_FONT);
  }
}

#MynyfyMobileFilter {
  padding: 10px;
  .searchOption {
    margin-bottom: 20px;
    padding-left: 10px;
    cursor: pointer;
    border-left: 3px solid transparent;
    transition: border-color 0.3s ease;
  }
  .searchOption.active {
    border-left-color: var(--PRIMARY_BLUE);
  }
  .filterMobileContainer {
    padding: 0px 5px;
    margin: 5px 2px;
  }
}
